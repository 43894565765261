import "../App.css"

export default function _404() {
    return (

        <div className="the404">
            <h1>There's nothing here! Enjoy this picture of Tilly.</h1>
            <img src="/images/Tilly2.jpg" alt="" />
        </div>
    )
}